.floating-window {
  position: fixed;
  top: 80px;
  right: 0px;
  width: 320px;
  border-radius: 12px;
  z-index: 1000;
  background-color: transparent;
  transition: transform 0.3s ease-in-out;
}

.floating-window.open {
  transform: translateX(-20px);
}

.floating-window.closed {
  transform: translateX(0px);
}

.close-button {
  position: absolute;
  right: 0px;
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
  background: none;
  border: none;
  padding: 5px;
}

.close-button:hover {
  color: #a8ee95;
}
