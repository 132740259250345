.projects-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 30px;
}

.projects-title {
  margin-left: 35px;
}

.project-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #252525;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.project-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.project-details p {
  font-size: 1rem;
  color: var(--text-uiux-2);
}

.github-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--bg-uiux-1);
  color: var(--text-uiux-5);
  font-size: 1.5rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.github-link:hover {
  background-color: var(--bg-uiux-5);
  color: var(--bg-uiux-4);
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .projects-container {
    grid-template-columns: 1fr;
    gap: 15px;
    margin: 20px;
  }

  .projects-title {
    margin-left: 20px;
    text-align: center;
  }

  .project-item {
    padding: 10px;
    gap: 10px;
  }

  .project-details p {
    font-size: 0.9rem;
  }

  .github-link {
    width: 35px;
    height: 35px;
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .projects-container {
    margin: 10px;
    gap: 10px;
  }

  .project-item {
    padding: 8px;
  }

  .project-details p {
    font-size: 0.8rem;
  }

  .github-link {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }
}
