.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: var(--bg-uiux-4, #121212);
  color: var(--text-uiux-5, #a8ee95);
  border: none;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1000;
}

.scroll-to-top:hover {
  opacity: 1;
  transform: scale(1.1);
}

.snap-container {
  scroll-snap-type: y mandatory;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.snap-container::-webkit-scrollbar {
  display: none;
}
