.education-certifications-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 0 10px;
}

.education-items {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: var(--bg-light);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.certifications-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 12px;
  background-color: var(--bg-light);
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.education-item,
.certification-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  background-color: #252525;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.education-header,
.certification-header {
  display: flex;
  align-items: center;
}

.institution-logo,
.certification-logo {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 10px;
}

.education-details p,
.certification-details p {
  margin: 5px 0;
}

.view-cv-button-container {
  text-align: center;
  margin-top: 30px;
}

.view-cv-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: var(--bg-dark);
  background-color: #252525;
  font-family: "Kanit", sans-serif;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 1s ease, transform 0.2s ease;
}

.view-cv-button:hover {
  background-color: #9f96d9;
  transform: scale(1.05);
  color: #e0e0e0;
}

@media (max-width: 768px) {
  .education-certifications-container {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .education-items,
  .certifications-items {
    padding: 15px;
    gap: 10px;
  }

  .certifications-items {
    grid-template-columns: 1fr;
  }

  .view-cv-button-container {
    margin-top: 20px;
  }

  .view-cv-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .education-certifications-container {
    margin: 0;
    gap: 10px;
  }

  .education-items,
  .certifications-items {
    padding: 10px;
  }

  .view-cv-button {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}
