.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--bg-uiux-4, #121212);
  opacity: 0.7;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.hamburger-menu {
  display: none;
  background: none;
  border: none;
  color: var(--text-uiux-2, #e0e0e0);
  font-size: 1.5rem;
  cursor: pointer;
}

.nav-link {
  color: var(--text-uiux-2, #e0e0e0);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  margin: 0 1rem;
}

.nav-link.active {
  color: var(--text-uiux-5, #a8ee95);
  font-weight: bold;
}

.nav-link:hover {
  color: var(--text-uiux-5, #a8ee95);
}

@media (max-width: 768px) {
  .hamburger-menu {
    display: block;
    margin: 50%;
  }

  .nav-links {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--bg-uiux-4, #121212);
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    width: 100%;
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    z-index: 1000;
    display: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .nav-links.open {
    display: flex;
    transform: translateY(0);
    opacity: 1;
  }

  .nav-link {
    margin: 0.5rem 0;
    width: 100%;
    padding: 0.5rem;
    text-align: left;
    transition: background-color 0.3s ease;
  }

  .nav-link:hover {
    background-color: var(--text-uiux-5, #1a1a1a);
  }
}

.language-buttons {
  position: absolute;
  right: 10vh;
  top: 2vh;
  display: flex;
  gap: 10px;
}

.language-buttons button {
  background-color: #fff;
  color: #262626;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.language-buttons button:hover {
  background-color: #8e58da;
  color: #fff;
}

.fade-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 9999;
  animation: fadeOutIn 2s ease-in-out;
  pointer-events: none; /* Evita interferencias con los clics */
}

@keyframes fadeOutIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
