.snap-section {
  scroll-snap-align: none;
  scroll-snap-type: none;
  height: 110vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.snap-section {
  overflow: hidden;
}

.snap-section.experience-section {
  height: 340vh;
  overflow: visible;
}

@media (max-width: 1024px) {
  .snap-section {
    height: 100vh;
    padding: 1rem;
  }

  .snap-section.experience-section {
    height: 250vh;
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .snap-section {
    height: 90vh;
    padding: 0.5rem;
  }

  .snap-section.experience-section {
    height: 200vh;
  }
}

@media (max-width: 480px) {
  .snap-section {
    height: auto;
    min-height: 80vh;
    padding: 0.5rem;
  }

  .snap-section.experience-section {
    min-height: 395vh;
    padding: 0;
  }
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.fade-out {
  opacity: 0;
  transform: translateY(20px);
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
