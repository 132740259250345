.about-me-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 50px;
  margin: 0 auto;
  min-height: 80vh;
}

.about-me-left,
.about-me-right {
  flex: 1;
}

.about-me-left {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bio-container {
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 12px;
  background-color: var(--bg-dark);
  color: var(--text-light);
}

.bio-content {
  max-width: 1000px;
}

.bio-content p {
  font-size: 1rem;
  line-height: 1.6;
  text-align: justify;
}

.skills-container,
.interests-container {
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 12px;
  background-color: var(--secondary-color);
  color: var(--bg-dark);
}

.skills-content {
  display: flex;
  justify-content: space-between;
}

.skills-content h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.skills-content ul,
.interests-container ul {
  list-style: none;
  padding-left: 20px;
}

.skills-content li,
.interests-container li {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 8px;
  position: relative;
  padding-left: 20px;
}

.skills-content li::before,
.interests-container li::before {
  content: "•";
  color: #a8ee95;
  position: absolute;
  left: 0;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .about-me-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .skills-content {
    flex-direction: column;
    align-items: center;
  }
}

.download-cv {
  margin-top: 20px;
  text-align: left;
}

.download-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 500;
  color: #121212;
  background-color: #a8ee95;
  border: none;
  border-radius: 12px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.download-button:hover {
  background-color: #9f96d9;
  transform: scale(1.05);
}
