.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #262626;
  color: #e0e0e0;
}

.footer-left,
.footer-right,
.footer-section {
  flex: 1;
  margin: 10px;
}

.social-links a {
  display: inline-block;
  margin-right: 10px;
  text-decoration: none;
  color: #e0e0e0;
}

.social-links a:hover {
  text-decoration: underline;
}

.carousel-container {
  width: 100%;
  max-width: 800px;
  height: 300px;
  overflow: hidden;
  margin: 0 auto;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.testimonial {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #121212;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  height: 150px;
  width: 500px;
}

.testimonial:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}

.testimonial-photo {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin-right: 20px;
  border: 3px solid #8e58da;
}

.testimonial-content {
  display: flex;
  flex-direction: column;
}

.testimonial-comment {
  font-style: italic;
  margin: 0 0 10px 0;
  color: #e0e0e0;
  font-size: 16px;
}

.testimonial-name {
  font-weight: bold;
  margin: 0 0 5px 0;
  color: #e0e0e0;
  font-size: 18px;
}

.testimonial-position {
  margin: 0;
  color: #9f96d9;
  font-size: 14px;
}

.availability-message h3 {
  color: #a8ee95;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
  }

  .availability-message {
    order: 1;
  }

  .footer-right {
    order: 2;
  }

  .contact-info {
    order: 3;
  }

  .footer-section {
    width: 100%;
    margin: 20px 0;
  }

  .social-links a {
    margin-right: 15px;
  }

  .carousel-container {
    height: auto;
  }

  .testimonial {
    width: 100%;
  }
}

.footer-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.copyright-section {
  text-align: center;
  margin-top: 20px;
}

.copyright-section p {
  margin: 5px 0;
  color: #e0e0e0;
}

.text-sm {
  font-size: 14px;
}

.text-xs {
  font-size: 12px;
  color: #999999;
}
