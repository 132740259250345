.hero-container {
  width: 600px;
  margin-top: -20vh;
  margin-left: -70vh;
  text-align: left;
  font-family: "Kanit", sans-serif;
  color: var(--text-uiux-2, #e0e0e0);
}

@media (max-width: 768px) {
  .hero-container {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  .static-text.large {
    font-size: 1.5rem;
    color: var(--text-uiux-5, #a8ee95);
    margin-bottom: 1rem;
    max-width: 35%;
  }

  .animated-text.small {
    font-size: 2rem;
    font-weight: 400;
  }

  .passionate-text.small {
    font-size: 1.8rem;
    font-weight: 300;
    color: var(--text-uiux-2, #e0e0e0);
    margin: 0.5rem 0;
  }

  .dynamic-text.small {
    font-size: 1.8rem;
    color: var(--text-uiux-3, #9f96d9);
  }

  .subtext.small {
    font-size: 1rem;
    padding-top: 10%;
    padding-bottom: 5%;
    line-height: 1.5;
    max-width: 85%;
    text-align: justify;
    margin: 1rem auto;
  }
}

#tsparticles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.static-text {
  font-size: 2rem;
  color: var(--text-uiux-5, #a8ee95);
  margin-bottom: 1rem;
}

.animated-text {
  font-size: 3.5rem;
  font-weight: 300;
  display: inline;
  white-space: nowrap;
}

.dynamic-text {
  color: var(--text-uiux-3, #9f96d9);
  display: inline-block;
}

.blinking-cursor {
  margin-top: 0;
  display: inline-block;
  width: 1.5px;
  height: 1em;
  background-color: currentColor;
  animation: blink 0.8s steps(2, start) infinite;
}

.hidden {
  display: none;
}

.visible {
  animation: blink 1s steps(1, start) infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.subtext {
  color: var(--text-uiux-3, #9f96d9);
  margin-top: 1rem;
  line-height: 1.5;
  max-width: 600px;
  font-size: 1.2rem;
  margin-left: auto;
  margin-right: 0;
  text-align: justify;
  opacity: 0;
  transform: translateX(20px);
  animation: fade-in-right 0.8s ease-out 0.5s forwards;
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.social-media {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 2rem;
  top: 45%;
  transform: translateY(-50%);
}

.social-icon {
  font-size: 2rem;
  color: var(--text-uiux-2, #e0e0e0);
  margin: 0.5rem 0;
  opacity: 0;
  transform: translateY(-100px);
  animation: fall 0.8s ease-out forwards;
  transition: color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
}

.social-icon:nth-child(1) {
  animation-delay: 0.4s;
}
.social-icon:nth-child(2) {
  animation-delay: 0.6s;
}
.social-icon:nth-child(3) {
  animation-delay: 0.8s;
}
.social-icon:nth-child(4) {
  animation-delay: 0.8s;
}

@keyframes fall {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.social-icon:hover {
  color: var(--text-uiux-5, #a8ee95);
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .social-media {
    flex-direction: row;
    position: static;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }

  .social-icon {
    font-size: 1.5rem;
    margin: 0;
  }
}

@media (max-width: 480px) {
  .social-icon {
    font-size: 1.2rem;
  }

  .social-media {
    gap: 0.5rem;
  }
}
