html,
body {
  background-color: #121212;
  color: #e0e0e0;
  font-family: "Kanit", sans-serif;
  margin: 0;
  padding: 0;
  overflow: auto;
  scroll-behavior: smooth;
}

.bg-uiux-1 {
  background-color: #8e58da;
}
.bg-uiux-2 {
  background-color: #e0e0e0;
}
.bg-uiux-3 {
  background-color: #9f96d9;
}
.bg-uiux-4 {
  background-color: #121212;
}
.bg-uiux-5 {
  background-color: #a8ee95;
}

.text-uiux-1 {
  color: #8e58da;
}
.text-uiux-2 {
  color: #e0e0e0;
}
.text-uiux-3 {
  color: #9f96d9;
}
.text-uiux-4 {
  color: #121212;
}
.text-uiux-5 {
  color: #a8ee95;
}

.border-uiux-1 {
  border-color: #8e58da;
}
.border-uiux-2 {
  border-color: #e0e0e0;
}
.border-uiux-3 {
  border-color: #9f96d9;
}
.border-uiux-4 {
  border-color: #121212;
}
.border-uiux-5 {
  border-color: #a8ee95;
}
